// 引入工具函数
import { http, httpimg } from '../../api/index'

// 现存统计
export const statisticNowRegLive = params => http.post('/ohealth/api/v1/isolation/statistic/statisticNowRegLive', params)

// 新增统计
export const statisticNewAddRegLive = params => http.get('/ohealth/api/v1/isolation/statistic/statisticNewAddRegLive', params)

// 隔离点统计
export const statisticIsolationPoint = params => http.post('/ohealth/api/v1/isolation/statistic/statisticIsolationPoint', params)

// 每日
export const statisticCurrentDay = params => http.get('/ohealth/api/v1/isolation/statistic/statisticCurrentDay', params)

// 新增统计导出
export const exportExisting = params => httpimg.get('/ohealth/api/v1/isolation/excelexport/exportExisting', params)

// 现存统计导出
export const exportNowExisting = params => httpimg.get('/ohealth/api/v1/isolation/excelexport/exportNowExisting', params)

// 导出每日统计
export const exportStatisticCurrentDay = params => httpimg.get('/ohealth/api/v1/isolation/statistic/exportStatisticCurrentDay', params)

// 统计列表导出
export const exportStatisticIsolationPoint = params => httpimg.post('/ohealth/api/v1/isolation/statistic/exportStatisticIsolationPoint', params)

// 核酸统计列表
export const covidCheckStatistics = params => http.get('/ohealth/api/v1/isolation/covidcheck/covidCheckStatistics', params)

// 核酸导出统计列表
export const exportCovidCheckStatistics = params => httpimg.get('/ohealth/api/v1/isolation/covidcheck/exportCovidCheckStatistics', params)